<template>
  <div>
    <VueApexCharts ref="semiDonut" type="donut" :options="chartOptions" :series="series"></VueApexCharts>
    <span class="center-score">{{ point }}</span>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    VueApexCharts
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    series: {
      type: Array
    },
    point: {
      type: String
    }
  },
  data() {
    return {
      // series: this.data,
      // series: [],
      chartOptions: {
        labels: ["Positive", "Neutral", "Negative"],
        chart: {
          type: "donut"
        },
        dataLabels: {
          style: {
            fontSize: "10px",
            fontWeight: "normal"
          },
          dropShadow: {
            enabled: false
          }
        },
        tooltip: {
          enabled: false
        },
        plotOptions: {
          pie: {
            customScale: 1.2,
            startAngle: -90,
            endAngle: 90,
            offsetY: 30,
            donut: {
              size: "40%"
            }
          }
        },
        legend: {
          position: "right",
          offsetY: 0,
          markers: {
            fillColors: ["#48A2D8", "#6B93CC", "#D365A8"]
          }
        },
        grid: {
          padding: {
            bottom: -90
          }
        },
        fill: {
          colors: ["#48a2d8", "#6b93cc", "#d365a8"]
        }
        // responsive: [
        //   {
        //     breakpoint: 1500,
        //     options: {
        //       chart: {
        //         width: 200,
        //       },
        //       legend: {
        //         position: "top",
        //         offsetY: 10,
        //       },
        //     },
        //   },
        // ],
      }
      // chartOptions: {
      //   labels: ["만족해요", "보통", "별로예요"],
      //   chart: {
      //     type: "donut",
      //     // width: "100%",
      //     // height: "auto",
      //   },
      //   dataLabels: {
      //     style: {
      //       fontSize: "10px",
      //       fontWeight: "normal",
      //     },
      //     dropShadow: {
      //       enabled: false,
      //     },
      //   },
      //   plotOptions: {
      //     pie: {
      //       customScale: 1.2,
      //       startAngle: -90,
      //       endAngle: 90,
      //       offsetY: 20,
      //       donut: {
      //         size: "40%",
      //       },
      //     },
      //   },
      //   legend: {
      //     position: "right",
      //     offsetY: 0,
      //   },
      //   grid: {
      //     padding: {
      //       bottom: 0,
      //     },
      //   },
      //   responsive: [
      //     {
      //       breakpoint: 480,
      //       options: {
      //         chart: {
      //           width: 200,
      //         },
      //         legend: {
      //           position: "bottom",
      //         },
      //       },
      //     },
      //   ],
      // },
    };
  }
};
</script>
