<template>
  <div class="ReviewDetail modal">
    <div class="modal_cover" @click="close"></div>
    <div class="modal_content">
      <div class="m_header">
        <div>{{ this.productName }} 제품 리뷰 상세 데이터</div>
        <button @click="close" class="close">
          <span class="material-icons"> close </span>
        </button>
      </div>

      <div class="m_body">
        <div class="buttonWrap">
          <button :class="{ active: tabOne }" @click="handleTab(5)">
            {{ $t("five_point") }}
          </button>
          <button :class="{ active: tabTwo }" @click="handleTab(4)">
            {{ $t("four_point") }}
          </button>
          <button :class="{ active: tabThree }" @click="handleTab(3)">
            {{ $t("three_point") }}
          </button>
          <button :class="{ active: tabFour }" @click="handleTab(2)">
            {{ $t("two_point") }}
          </button>
          <button :class="{ active: tabFive }" @click="handleTab(1)">
            {{ $t("one_point") }}
          </button>
        </div>

        <div class="reviewList">
          <div>
            <table
              class="tr7"
              v-if="
                reviewList.filter((data) => data.rating == rating).length > 0
              "
            >
              <tr>
                <th>번호</th>
                <th>날짜</th>
                <th>리뷰 소스</th>
                <th>리뷰 내용</th>
                <th>신뢰도</th>
                <th>감성분석</th>
                <th>NPS 점수</th>
              </tr>
              <tr
                v-for="(data, i) in reviewList.filter(
                  (data) => data.rating == rating
                )"
                :key="i"
              >
                <td>{{ i + 1 }}</td>
                <td>
                  {{ moment(data.review_date).format("YYYY.MM.DD") }}
                </td>
                <td>{{ data.review_source }}</td>
                <td>{{ data.review }}</td>
                <td>{{ data.credibility == true ? "신뢰" : "미신뢰" }}</td>
                <td>
                  {{
                    data.sentiment_pred == 0
                      ? "보통"
                      : data.sentiment_pred == 1
                      ? "만족해요"
                      : "별로에요"
                  }}
                </td>
                <td>{{ data.NPS_Score }}</td>
              </tr>
            </table>

            <div class="nodata" v-else>데이터가 없습니다.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { eventBus } from "@/main";
import { mapState } from "vuex";
import moment from "moment";
export default {
  props: {
    reviewList: {
      type: Array,
    },
  },
  data() {
    return {
      moment: moment,
      visible: false,
      type: "amazon",
      asin: "",
      rating: 5,
      activeNames: ["1"],
      index: 0,
      tabOne: true,
      tabFour: false,
      tabThree: false,
      tabTwo: false,
      tabFive: false,
      ownVisible: true,
    };
  },
  // mounted() {
  //   this.getReviews();
  // },
  computed: {
    ...mapState(["userInfo", "productName"]),
  },
  methods: {
    close() {
      this.$emit("close");
    },
    // closeModal() {
    //   eventBus.$emit("closeModal", true);
    // },
    openModal() {
      this.$emit("openModal");
    },
    handleTab(tab) {
      this.tabFive = false;
      this.tabFour = false;
      this.tabTwo = false;
      this.tabThree = false;
      this.tabOne = false;
      this.rating = tab;
      if (tab == 5) {
        this.tabOne = true;
      } else if (tab == 4) {
        this.tabTwo = true;
      } else if (tab == 3) {
        this.tabThree = true;
      } else if (tab == 2) {
        this.tabFour = true;
      } else if (tab == 1) {
        this.tabFive = true;
      }
    },
    Loading() {
      this.$store.dispatch("LOADING", true);
    },
  },
};
</script>
