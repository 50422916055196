<template>
  <div class="review-bg">
    <Navbar />
    <div class="review-body">
      <TopBar />
      <div class="filter-bar boxarea">
        <SelectLayer />
        <button @click="move(0)">{{ $t("see_review_insight") }}</button>
      </div>
      <section v-if="bestFactor[0]" class="summary-bar">
        <div class="boxarea summary-box">
          <h2>
            <img src="@/assets/images/reviewmind/summary_01.png" alt="아이콘" />
            {{ $t("average_ratings") }}
          </h2>
          <h3>{{ (totalRating / totalCount).toFixed(2) }} / 5</h3>
          <p>
            <i class="fas fa-caret-up"></i>
            {{ compareRating }}%
            <span>{{ $t("month_on_month") }}</span>
          </p>
        </div>
        <div class="boxarea summary-box">
          <h2>
            <img src="@/assets/images/reviewmind/summary_02.png" alt="아이콘" />
            {{ $t("total_reviews") }}
          </h2>
          <h3>{{ comma(totalCount) }}</h3>
          <p>
            <i class="fas fa-caret-up"></i>
            {{ totalCount - lastMonthCount }}
            <span>{{ $t("month_on_month") }}</span>
          </p>
        </div>
        <div class="boxarea summary-box">
          <h2>
            <img src="@/assets/images/reviewmind/summary_03.png" alt="아이콘" />
            {{ $t("best_key_factor") }}
          </h2>
          <h3 v-if="bestFactor[0]">
            {{
              bestFactor[0]._id === 1
                ? "General"
                : bestFactor[0]._id === 2
                ? "Size"
                : bestFactor[0]._id === 3
                ? "Material"
                : bestFactor[0]._id === 4
                ? "Quality"
                : bestFactor[0]._id === 5
                ? "Color"
                : bestFactor[0]._id === 6
                ? "Price"
                : bestFactor[0]._id === 7
                ? "Picture"
                : bestFactor[0]._id === 8
                ? "Style"
                : bestFactor[0]._id === 9
                ? "Brand"
                : bestFactor[0]._id === 10
                ? "Shipping"
                : bestFactor[0]._id === 11
                ? "Durability"
                : bestFactor[0]._id === 12
                ? "Problem"
                : "General"
            }}
          </h3>
          <p>
            <span>{{ comma(bestFactor[0].total) }} {{ $t("reviews") }}</span>
          </p>
        </div>
        <div class="boxarea summary-box">
          <h2>
            <img src="@/assets/images/reviewmind/summary_04.png" alt="아이콘" />
            {{ $t("review_credibility") }}
          </h2>
          <h3 class="score">{{ getScore(credibility) }}</h3>
        </div>
      </section>
      <section class="chart">
        <div class="boxarea chart-box">
          <h2>
            <img src="@/assets/images/reviewmind/chart_01.png" alt="아이콘" />
            {{ $t("review_summary") }}
          </h2>
          <p v-if="seriesRating.length > 0 && currentLang == 'ko'">
            {{ term ? `지난 ${term}개월 동안` : "전체" }}
            {{ comma(ratingTotalCount) }} {{ $t("comment_2") }}
            {{ $t("comment_3") }}
          </p>
          <p v-else-if="seriesRating.length > 0 && currentLang == 'en'">
            {{
              term
                ? `You've got ${comma(
                    ratingTotalCount
                  )} reviews last ${term} months`
                : `You've got ${comma(ratingTotalCount)} reviews last months`
            }}
          </p>
          <div class="barChart">
            <Bar-Chart v-if="seriesRating.length > 0" :series="seriesRating" />
            <div v-else class="nodata">{{ $t("no_data") }}</div>
          </div>
          <button @click="handleReviews">
            <i v-if="loading" class="el-icon-loading"></i>
            <i v-else class="el-icon-document"></i>
            <!-- <span class="material-icons"> add </span> -->
          </button>
        </div>
        <div class="boxarea chart-box">
          <h2>
            <img src="@/assets/images/reviewmind/chart_02.png" alt="아이콘" />
            {{ $t("level_satisfaction") }}
            <p v-if="sentiment.length > 0">
              {{ comma(ratingTotalCount) }} {{ $t("reviews") }}
            </p>
          </h2>
          <Semi-Donut
            v-if="sentiment.length > 0"
            :series="sentiment"
            :point="centerPoint"
          />
          <div v-if="sentiment.length > 0" class="confidence">
            <h2>{{ $t("review_credibility") }}</h2>
            <el-progress
              :text-inside="true"
              :stroke-width="20"
              :percentage="credibilityByTerm"
              :format="format"
            ></el-progress>
            <p>{{ getScore(credibilityByTerm) }}</p>
          </div>
          <div v-else class="nodata">{{ $t("no_data") }}</div>
        </div>
      </section>
      <section>
        <div class="negative boxarea">
          <h2>
            <img src="@/assets/images/reviewmind/chart_04.png" alt="아이콘" />
            {{ $t("negative_management") }}
            <button @click="move(1)">
              {{ $t("see_reputation_management") }}
            </button>
          </h2>
          <p v-if="sentiment.length > 0 && currentLang == 'ko'">
            {{ term == "" ? "현재까지" : "지난" + term + "개월동안" }} 받은 리뷰
            {{ comma(chartTotalCount) }}개 중, 별점 3점 이하를 받은 리뷰가
            {{ underThree }}개 입니다.
            <br />
            {{ term == "" ? "현재까지" : "지난" + term + "개월동안" }} 감성 점수
            Negative를 받은 리뷰가 <span>{{ sentiment_2 }}</span
            >개 입니다.
          </p>
          <p v-else-if="sentiment.length > 0 && currentLang == 'en'">
            {{ comma(underThree) }} of the reviews
            {{ term == "" ? "until now" : "in the last " + term + " months" }}
            rated 3 stars or less.
            <br />
            {{ comma(sentiment_2) }} of the reviews
            {{ term == "" ? "until now" : "in the last " + term + " months" }}
            rated negative sentiment score.
          </p>
          <div class="chartBox">
            <VueApexCharts
              :class="{ fullSize: index == 1 }"
              ref="chart"
              type="line"
              height="260"
              :options="chartOptions"
              :series="series"
            ></VueApexCharts>
          </div>
        </div>
      </section>
    </div>
    <transition name="fade">
      <div class="dim" v-if="modalVisible">
        <ReviewDetail @close="close" :reviewList="reviewList" />
        <div class="dimbg" @click="close"></div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapState } from "vuex";
// import HeaderInfo from "@/components/dashboard/HeaderInfo";
import SemiDonut from "@/components/charts/SemiDonut";
import BarChart from "@/components/charts/BarChart";
// import SimpleDonut from "@/components/charts/SimpleDonut";
// import LineColumn from "@/components/charts/LineColumn";
import Navbar from "@/components/Navbar";
import TopBar from "@/components/dashboard/TopBar";
import SelectLayer from "@/components/dashboard/SelectLayer";
import VueApexCharts from "vue-apexcharts";
import ReviewDetail from "@/components/pop/ReviewDetail";
import {
  fetchReviewScore,
  fetchSentimentPred,
  // fetchReviewStatus,
  fetchReviewRating,
  fetchNegativeRating,
  fetchReviews,
} from "@/api/review";
import { format } from "@/mixins/format";
import lang from "@/mixins/lang";
export default {
  mixins: [format, lang],
  components: {
    SemiDonut,
    BarChart,
    // SimpleDonut,
    Navbar,
    TopBar,
    SelectLayer,
    VueApexCharts,
    ReviewDetail,
  },
  data() {
    return {
      loading: false,
      modalVisible: false,
      index: "1",
      chartTerm: "12",
      center: 0,
      totalSum: 0,
      compareRating: 0,
      lastMonthRating: 0,
      lastMonthCount: 0,
      negReviewTotal: 0,
      centerPoint: "",
      reviewList: [],
      labels: [],
      reviewsPerMonth: [],
      posReviews: [],
      neuReviews: [],
      negReviews: [],
      oneReviews: [],
      twoReviews: [],
      threeReviews: [],
      seriesRating: [],
      score: "",
      status: 1,
      totalCount: 0,
      avg: 0,
      credibility: 0,
      credibilityByTerm: 0,
      bestFactor: "",
      sentiment: [],
      // customerSatisfaction: [90, 53, 23],
      // since: "6",
      reviewStatus: [],
      review_X: [],
      rating: [],
      ratings: [],
      totalRating: 0,
      ratingTotalCount: 0,
      chartTotalCount: 0,
      chartNegCount: 0,
      ratingSum: 0,
      underThree: 0,
      sentiment_2: 0,
      chartWidth: "",
      series: [],
      chartOptions: {
        colors: ["#E0EBFE", "#57E69C", "#6B93CC", "#D365A8"],
        chart: {
          id: "mychart",
          height: 350,
          type: "line",
          background: "#fafafa",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "30%",
          },
        },
        fill: {
          colors: ["#E0EBFE"],
        },
        stroke: {
          curve: "smooth",
          width: [0, 3, 3, 3], // 그래프 두께 series배열 순서
          colors: ["#D365A8", "#57E69C", "#6B93CC"],
        },
        // dataLabels: {
        //   enabled: false, // 바 그래프에 값 표시
        //   enabledOnSeries: [1, 2, 3],
        // },
        legend: {
          position: "right",
          markers: {
            fillColors: ["#E0EBFE", "#57E69C", "#6B93CC", "#D365A8"],
          },
        },
        tooltip: {},
        xaxis: {
          type: "category",
          categories: [],
          labels: {
            show: true,
            rotate: -45,
            rotateAlways: false,
            hideOverlappingLabels: true,
          },
        },
        yaxis: [
          {
            show: false,
          },
          {
            show: false,
            seriesName: "2점", // y축 통합을 위해 시리즈네임 설정
            opposite: true,
            //  title: {
            //   text: "Social Media",
            // },
          },
          {
            show: false,
            seriesName: "2점",
            opposite: true,
          },
          {
            show: false,
            seriesName: "2점",
            opposite: true,
          },
        ],
      },
    };
  },
  created() {
    this.$store.dispatch("DASHBOARD_MENU", 1);
  },
  mounted() {
    this.getReviewScore();
    this.getSentimentPred();
    this.getReviewRating();
    if (this.term == "") {
      this.mergeData();
    }
  },
  watch: {
    asin: function () {
      // this.getReviewStatus();
      this.getSentimentPred();
      this.getReviewScore();
      this.getReviewRating();
    },
    term: function () {
      this.getReviewRating();
      // this.getReviewStatus();
      this.getSentimentPred();
      this.mergeData();
      if (this.term == "") {
        this.index = 1;
      } else {
        this.index = 2;
      }
    },
  },
  computed: {
    ...mapState(["navVisible", "footerVisible", "term", "asin", "currentLang"]),
  },

  methods: {
    close() {
      this.modalVisible = false;
    },

    handleReviews() {
      this.loading = true;
      let params = {
        url: this.url,
        asin: this.asin,
        term: this.term,
        type: this.type,
      };
      fetchReviews(params).then((res) => {
        if (res.data.status == 200) {
          this.reviewList = res.data.data;
          this.modalVisible = true;
        } else {
          return alert(res.data.message);
        }
        this.loading = false;
      });
    },
    async getNegativeRatings() {
      this.reviewsPerMonth = [];
      this.threeReviews = [];
      this.twoReviews = [];
      this.oneReviews = [];
      this.labels = [];

      let params = {
        asin: this.asin,
        term: this.term,
      };

      fetchSentimentPred(params).then((res) => {
        if (res.status == 200) {
          this.sentiment_2 = res.data.data.sentiment[2];
        } else {
          return alert(res.data.message);
        }
      });
      fetchReviewRating(params).then((res) => {
        if (res.status == 200) {
          this.ratings = res.data.data.rating;
          let data = [];
          for (let i = 5; i > 0; i--) {
            let input = 0;
            for (let j = 0; j < this.ratings.length; j++) {
              if (this.ratings[j]._id == i) {
                input = this.ratings[j].count;
              }
            }
            data.push(input);
          }
          this.underThree = 0;
          data.forEach((item, i) => {
            if (i > 1) {
              this.underThree += item;
            }
          });
        }
      });
      await fetchNegativeRating(params).then((res) => {
        this.labels = res.data.data.labels;
        this.reviewsPerMonth = res.data.data.reviewsPerMonth;
        this.oneReviews = res.data.data.onePoint;
        this.twoReviews = res.data.data.twoPoint;
        this.threeReviews = res.data.data.threePoint;
        // for (let i = 12 - this.chartTerm; i < 12; i++) {
        //   this.labels.push(res.data.data.labels[i]);
        //   this.reviewsPerMonth.push(res.data.data.reviewsPerMonth[i]);
        //   this.oneReviews.push(res.data.data.onePoint[i]);
        //   this.twoReviews.push(res.data.data.twoPoint[i]);
        //   this.threeReviews.push(res.data.data.threePoint[i]);
        // }
      });
    },
    format(percentage) {
      if (percentage === 100) {
        this.score = "A+";
      } else {
        this.score = "C+";
      }
      return `${percentage}%`;
    },
    move(type) {
      if (type == 0) {
        this.$router.push("/dashboard/reviewmind2");
      } else {
        this.$router.push("/dashboard/reviewmind7");
      }
    },
    getReviewRating() {
      let params = {
        asin: this.asin,
        term: this.term,
      };
      fetchReviewRating(params).then((res) => {
        if (res.status == 200) {
          this.ratingTotalCount = res.data.data.totalCount;
          this.ratings = res.data.data.rating;
          let data = [];
          for (let i = 5; i > 0; i--) {
            let input = 0;
            for (let j = 0; j < this.ratings.length; j++) {
              if (this.ratings[j]._id == i) {
                input = this.ratings[j].count;
              }
            }
            data.push(input);
          }
          this.seriesRating = [{ data: data }];
          this.totalSum = res.data.data.totalSum;
          this.ratingSum = this.sumArray(data);
          this.centerPoint = (this.totalSum / this.ratingSum).toFixed(2);
        }
      });
    },

    getReviewScore() {
      fetchReviewScore().then((res) => {
        if (res.status == 200) {
          this.lastMonthRating = res.data.data.lastMonthRating;
          this.lastMonthCount = res.data.data.lastMonthCount;
          this.totalCount = res.data.data.totalCount;
          this.avg = res.data.data.avg;
          this.credibility = res.data.data.credibility;
          this.bestFactor = res.data.data.bestFactor;
          this.rating = res.data.data.rating;
          // this.series.data = this.rating.map((item) => {
          //   if (item._id > 0) {
          //     return item.count;
          //   }
          // });
          let lastData = this.lastMonthRating.map((item) => {
            if (item._id > 0) {
              return item.total;
            }
          });
          let data = this.rating.map((item) => {
            if (item._id > 0) {
              return item.total;
            }
          });
          this.lastTotalRating = this.sumArray(lastData);
          this.totalRating = this.sumArray(data);
          this.center = (this.totalRating / this.totalCount).toFixed(2);
          let gapRating = (this.lastTotalRating / this.lastMonthCount).toFixed(
            2
          );
          this.compareRating = (
            ((this.center - gapRating) / gapRating) *
            100
          ).toFixed(2);
        } else {
          return alert(res.data.message);
        }
      });
    },
    sumArray(array) {
      let result = 0.0;

      for (let i = 0; i < array.length; i++) result += array[i];

      return result;
    },
    getSentimentPred() {
      let params = {
        asin: this.asin,
        term: this.term,
      };

      fetchSentimentPred(params).then((res) => {
        if (res.status == 200) {
          this.sentiment = res.data.data.sentiment;
          this.credibilityByTerm = res.data.data.credibility;
        } else {
          return alert(res.data.message);
        }
      });
    },
    // async getReviewStatus() {
    //   await fetchReviewStatus(this.asin).then(res => {

    //     this.labels = [];
    //     this.reviewsPerMonth = [];
    //     this.posReviews = [];
    //     this.neuReviews = [];
    //     this.negReviews = [];

    //     for (let i = 12 - this.term; i < 12; i++) {
    //       this.labels.push(res.data.data.labels[i]);
    //       this.reviewsPerMonth.push(res.data.data.reviewsPerMonth[i]);
    //       this.posReviews.push(res.data.data.posReviews[i]);
    //       this.neuReviews.push(res.data.data.neuReviews[i]);
    //       this.negReviews.push(res.data.data.negReviews[i]);
    //     }
    //     this.negReviewTotal = this.sumArray(this.reviewsPerMonth);
    //     // if (res.status == 200) {

    //     //   // this.review_X = res.data.data.labels;
    //     //   // this.chartOptions.labels = res.data.data.labels;
    //     // }
    //   });
    // },
    async mergeData() {
      // this.reviewsPerMonth = [];
      // this.threeReviews = [];
      // this.twoReviews = [];
      // this.oneReviews = [];
      // this.labels = [];
      // this.chartTotalCount = 0;
      await this.getNegativeRatings();
      this.chartTotalCount = 0;
      this.reviewsPerMonth.forEach((item) => {
        this.chartTotalCount += item;
      });
      this.series = [
        {
          name: "negative reviews",
          type: "column",
          data: this.reviewsPerMonth,
        },
        {
          name: "3P",
          type: "line",
          data: this.threeReviews,
        },
        {
          name: "2P",
          type: "line",
          data: this.twoReviews,
        },
        {
          name: "1P",
          type: "line",
          data: this.oneReviews,
        },
      ];

      this.$refs.chart.updateOptions(
        {
          xaxis: {
            categories: this.labels,
          },
        },
        true
      );
    },
  },
  beforeDestroy() {
    this.$store.dispatch("SET_ASIN", "");
    this.$store.dispatch("SET_URL", "");
    this.$store.dispatch("VIEW_TERM", "");
  },
};
</script>
